import "./temp-landing.scss";
import KP6 from "assets/KP6.jpg";

export const TempLanding = () => {
  return (
    <div className="temp-wrapper">
      <div className="temp-text">Coming Soon!!</div>
      <img src={KP6} alt="soap" />
    </div>
  );
};
