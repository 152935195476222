import { Banner } from "component/banner/banner";
import { Header } from "component/header/header";
import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "Pages/home/home";
import { About } from "Pages/about/about";
import { TempLanding } from "Pages/temp-landing/temp-landing";

export default function App() {
  return (
    <div className="App">
      {/* <Header />
      <Banner /> */}
      <Routes>
        <Route path="*" element={<TempLanding />} />
      </Routes>
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes> */}
    </div>
  );
}
